.vessel-root {
    background: #000000;
    position: relative;
}

.trailer_slot {
    fill: none;
    stroke-width: 1;
}

.slot_free {
    stroke: green;
}
.slot_occupied {
    stroke: red;
}
.slot_unknown {
    stroke: orange;
}

.trailer_slot:hover{
    fill: antiquewhite;
    fill-opacity: 0.4;
    cursor: pointer;
}

.dashed_border {
    stroke-dasharray: 3;
}

.noSelect {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}