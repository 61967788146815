.custom-tooltip {
    background: rgba(255, 255, 255, 0.9); /* White, semi-transparent */
    border-radius: 8px;
    padding: 12px;
    color: #000000; /* Black text for contrast */
    font-size: 14px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    
}

.custom-tooltip .tooltip-label {
    font-weight: bold;
    margin-bottom: 8px;
    display: block;
}

.custom-tooltip .tooltip-entry {
    margin-bottom: 6px;
}

.tooltip-content div {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center; /* Center vertically */
}

.tooltip-content strong {
    margin-right: 8px; /* Space between label and value */
}
